<template>
  <div class="address-page">
    <van-form @submit="onSubmit">
      <van-field
        v-model="ReceiverName"
        name="ReceiverName"
        :label="PAGELANG.收货人"
        :placeholder="PAGELANG.请输入收货人姓名"
        :rules="[{ required: true, message: PAGELANG.收货人姓名不能为空 }]"
      />
      <van-field
        v-model="MobilePhone"
        name="MobilePhone"
        :label="PAGELANG.手机号码"
        :placeholder="PAGELANG.请输入手机号"
        :rules="[{ required: true, message: PAGELANG.手机号码不能为空 }]"
      />

      <van-field
        v-model="Country"
        is-link
        readonly
        name="picker"
        :label="PAGELANG.国家"
        :placeholder="PAGELANG.选择国家"
        @click="showCountry = true"
        :rules="[{ required: true, message: PAGELANG.请选择国家 }]"
      />
      <van-popup v-model:show="showCountry" position="bottom">
        <van-picker
          name="CountryID"
          :title="PAGELANG.选择国家"
          :columns="countryList"
          :columns-field-names="addressFieldName"
          :loading="countryLoading"
          v-model="CountryID"
          @confirm="onConfirmCountry"
          @cancel="showCountry = false"
        />
      </van-popup>

      <van-field
        v-model="Area"
        is-link
        readonly
        name="picker"
        :label="PAGELANG.area"
        :placeholder="PAGELANG.选择省市区"
        @click="checkCountry"
        :rules="[{ required: true, message: PAGELANG.请选择所在区域 }]"
      />
      <van-popup v-model:show="showArea" position="bottom">
        <van-cascader
          name="AreaIDs"
          :title="PAGELANG.area"
          :options="addressList"
          :field-names="addressFieldName"
          :loading="addressLoading"
          v-model="AreaID"
          @finish="onConfirm"
          @close="showArea = false"
        />
      </van-popup>

      <van-field
        v-model="AddressDetail"
        name="AddressDetail"
        rows="4"
        autosize
        :label="PAGELANG.详细地址"
        type="textarea"
        :placeholder="PAGELANG.小区楼栋门牌村等"
        :rules="[{ required: true, message: PAGELANG.详细地址不能为空 }]"
      />
      <van-field
        name="IsDefault"
        :label="PAGELANG.设置为默认地址"
        label-width="8em"
        input-align="right"
      >
        <template #input>
          <van-switch
            v-model="IsDefault"
            size="20"
            active-color="#66dca0"
            active-value="1"
            inactive-value="0"
          />
        </template>
      </van-field>

      <div class="add-btn-block">
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          >{{ SYSLANG.save }}</van-button
        >
      </div>
    </van-form>

    <van-button
      v-if="addrid > 0"
      plain
      square
      block
      type="primary"
      class="btn-delete"
      @click="onDelete"
      >{{ SYSLANG.delete }}</van-button
    >
  </div>
</template>

<script>
import {
  showConfirmDialog,
  showToast,
  showLoadingToast,
  closeToast,
} from "vant";
import { getCookie } from "../util";
export default {
  data() {
    return {
      addrid: 0,
      addressList: [],
      addressLoading: true,
      addressFieldName: {
        text: "region_name",
        value: "region_id",
        children: "children",
      },
      ReceiverName: "",
      MobilePhone: "",
      showArea: false,
      Area: "",
      AreaID: 0,
      AreaIDs: [],
      AddressDetail: "",
      IsDefault: false,
      showCountry: false,
      Country: "",
      CountryID: [],
      oleCountryID: 0,
      countryList: [],
      countryLoading: true,
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.address;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.countryLoading = true;
    this.axios.get(this.actions.address_country).then(({ data }) => {
      closeToast();
      let { code, msg, list } = data;
      console.log("address_area", msg);
      if (code == "SUCCESS") {
        this.countryList = list;
      }

      this.countryLoading = false;
    });

    if (this.$route.query.addrid) {
      this.addrid = this.$route.query.addrid;
      if (!isNaN(this.addrid) && this.addrid > 0) {
        this.getAddress();
      }
    }
  },
  methods: {
    getAddress() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.address_detail + this.addrid)
        .then(({ data }) => {
          let { code, msg, detail } = data;
          console.log(code, msg, detail);
          if (code != "SUCCESS") {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          } else if (detail) {
            this.ReceiverName = detail.ReceiverName;
            this.MobilePhone = detail.MobilePhone;
            this.Country = detail.Country;
            this.CountryID[0] = detail.fkAreaIDL0;
            this.Area = detail.Area;
            this.AreaIDs = detail.AreaIDs;
            this.AreaID = detail.AreaIDs[detail.AreaIDs.length - 1];
            this.AddressDetail = detail.AddressDetail;
            this.IsDefault = detail.IsDefault;

            this.getArea();
          } else {
            this.addrid = 0;
          }
        })
        .catch((error) => {
          console.log("views.AddressDetail.getAddress.error", error);
          closeToast();
          this.getAddress();
        });
    },
    onConfirmCountry(val) {
      console.log(val);
      let Country = "";
      for (let i = 0; i < val.selectedOptions.length; i++) {
        if (Country != "" && val.selectedOptions[i].region_name) {
          Country += ",";
        }
        if (val.selectedOptions[i].region_name) {
          Country += val.selectedOptions[i].region_name;
        }
      }
      this.Country = Country;
      this.showCountry = false;

      console.log(this.oleCountryID, val.selectedValues[0]);
      if (this.oleCountryID != val.selectedValues[0]) {
        this.AreaIDs = [];
        this.Area = "";

        this.CountryID = val.selectedValues;
        this.oleCountryID = val.selectedValues[0];
        this.getArea();
      }
    },
    checkCountry() {
      if (this.CountryID.length <= 0) {
        showToast(this.PAGELANG.请选择国家);
        return;
      }
      this.showArea = true;
    },
    getArea() {
      this.addressLoading = true;
      this.axios
        .get(this.actions.address_area + this.CountryID[0])
        .then(({ data }) => {
          closeToast();
          let { code, msg, list } = data;
          console.log("address_area", msg);
          if (code == "SUCCESS") {
            this.addressList = list;
          }

          this.addressLoading = false;
        })
        .catch((error) => {
          console.log("views.AddressDetail.getArea.error", error);
          closeToast();
          this.getArea();
        });
    },
    onConfirm({ selectedOptions }) {
      let Area = "";
      this.AreaIDs = [];
      for (let i = 0; i < selectedOptions.length; i++) {
        if (Area != "" && selectedOptions[i].region_name) {
          Area += ",";
        }
        if (selectedOptions[i].region_name) {
          this.AreaIDs.push(selectedOptions[i].region_id);
          Area += selectedOptions[i].region_name;
        }
      }
      this.Area = Area;
      this.showArea = false;
      console.log("AreaID", this.AreaID);
    },
    onChange(val) {
      console.log("change", val);
    },
    onSubmit(postdata) {
      console.log("submit", postdata);
      postdata.addrid = this.addrid;
      postdata.AreaIDs = JSON.stringify(this.AreaIDs);
      postdata.CountryID = this.CountryID[0];

      showLoadingToast({
        message: this.SYSLANG.正在保存,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .post(this.actions.address_save, postdata)
        .then(({ data }) => {
          closeToast();
          let { code, msg } = data;
          showToast({
            message:
              code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
            forbidClick: true,
            onClose: () => {
              if (code == "SUCCESS") {
                this.$router.back();
              }
            },
          });
        })
        .catch((error) => {
          console.log("views.AddressDetail.onSubmit.error", error);
          closeToast();
          this.onSubmit(postdata);
        });
    },
    onDelete() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.deleteaddrss,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.SYSLANG.正在删除,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.address_delete + this.addrid)
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              showToast({
                message:
                  code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
                forbidClick: true,
                onClose: () => {
                  if (code == "SUCCESS") {
                    this.$router.back();
                  }
                },
              });
            })
            .catch((error) => {
              console.log("views.AddressDetail.onDelete.error", error);
              closeToast();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style src="../assets/css/address.css" scoped></style>
<style scoped>
.address-page {
  padding: 16px 0 var(--van-submit-bar-height) 0;
  --van-cell-vertical-padding: 14px;
}

.address-page >>> .van-cell::after {
  left: calc(var(--van-padding-md) + var(--van-field-label-width));
}

.btn-delete {
  --van-button-radius: 0;
  --van-button-primary-border-color: #fff;
  --van-button-primary-background: #fe295f;
  --van-button-default-height: var(--van-submit-bar-button-height);
  --van-button-normal-font-size: var(--van-submit-bar-font-size);
  margin-top: 16px;
}
</style>
